import React from 'react'
import Formulario from './form'
import Breadcrumbs from "../breadcrumbs";
import { graphql, useStaticQuery } from 'gatsby';

import {getBrText} from '../../helpers/helper.rendering'
import BannerTop from '../Global/Modules/MediaPush/BannerTop';

const Main = () => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              social{
                facebook
                instagram
                youtube
              }
              keys{
                captcha
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                        form
                    }
                    footer {
                        rows {
                            title_contact
                            button_contact {
                                value
                                link
                            }
                            title_office
                            title_social
                            title_newsletter
                        }
                    }
              }
          }
    }`)

    return realEstate.sections.services.form ? (
        <section class="tasaciones-main">        
            <BannerTop page="Tasaciones"/>
            <div class="container-fluid">
                <div class="row mt-lg-4 center align-items-center">
                    <div class="col-12">
                        <div class="row content-main align-items-start">
                            <div className="col-12 mt-lg-5 mt-2">
                               <Breadcrumbs
                                   props={[
                                   { name: realEstate.name, route: "/", location: "" },
                                   { name: "Servicios", route:   "", location: ""},
                                   ]}
                               />
                           </div>
                            <div class="col-lg-8">
                                <h1 class="mb-lg-5">{realEstate.sections.services.title}</h1>
                                <p class="mb-4 mt-5 mt-lg-0">{realEstate.sections.services.subtitle}</p>
                                   
                                <h5>
                                    {getBrText(realEstate.sections.services.paragraph).map((element) => (
                                        <>
                                            {element}
                                            <br />
                                        </>
                                    ))}
                                </h5>
                                <Formulario />
                            </div>
                            <div class="col-lg-3 offset-lg-1 text-right contact-nav d-none d-lg-flex align-items-center">
                                <div class="center-contact">
                                    <div class="item-contact">
                                        <h5>{realEstate.sections.footer.rows.title_contact}</h5>
                                        <a class="hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
                                    </div>
                                    <div class="item-contact">
                                        <h5>{realEstate.sections.footer.rows.title_office}{realEstate.branch_office[0].name}</h5>
                                        <p >{realEstate.branch_office[0].address}</p>
                                        <p >{realEstate.branch_office[0].city}</p>
                                        <p >{realEstate.branch_office[0].region}</p>
                                        <p >Teléfono {realEstate.branch_office[0].contact.phone}</p>
                                        <p >Whatsapp {realEstate.branch_office[0].contact.whatsapp}</p>
                                        <p >Matrícula {realEstate.branch_office[0].broker_id}</p>

                                    </div>
                                    <div class="item-contact">
                                        <h5>{realEstate.sections.footer.rows.title_office}{realEstate.branch_office[1].name}</h5>
                                        <p >{realEstate.branch_office[1].address}</p>
                                        <p >{realEstate.branch_office[1].city}</p>
                                        <p >{realEstate.branch_office[1].region}</p>
                                        <p >Teléfono {realEstate.branch_office[1].contact.phone}</p>
                                        <p >Whatsapp {realEstate.branch_office[1].contact.whatsapp}</p>
                                        <p >Matrícula {realEstate.branch_office[1].broker_id}</p>
                                    </div>
                                    <div class="item-contact">
                                        <h5>SEGUINOS</h5> 
                                        <a target="_blank" href={realEstate.social.instagram} class="d-inline"><i class="icon-instagram1"></i></a>
                                        <a target="_blank" href={realEstate.social.facebook} class="d-inline"><i class="icon-facebook1"></i></a>
                                        <a target="_blank" href={realEstate.social.youtube} class="d-inline"><i class="icon-youtube"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):''
}

export default Main